import React from "react";
import Layout from "../components/Layout";
import ButtonLink from "../components/ButtonLink";
import Seo from "../components/Seo";

const About = () => {
    return (
        <Layout>
            <Seo title="Contact - Arek Wtykło" />

            <div className="grid__main">
                <div className="grid__left">
                    <h1>Contact</h1>

                    <div className="text__lead">
                        <p>Would you like to discuss a project?</p>
                        <br />
                        <p>
                            No facebook, no twitter.
                            <br />
                            Just email me:
                            <br />
                            <a className="text__link" href="mailto:arek.wtyklo@arteneo.pl">
                                arek.wtyklo@arteneo.pl
                            </a>
                        </p>
                    </div>

                    <br />
                    <br />

                    <p className="buttonLink__text">You can also have a look at my projects or read more about me.</p>

                    <ButtonLink {...{ title: "Projects", to: "/projects" }} />
                    <ButtonLink {...{ title: "About me", to: "/about" }} />
                </div>
                <div className="grid__right">
                    <div className="pencil__wrapper">
                        <div className="pencil__main">
                            <div className="pencil__pencil">
                                <div className="pencil__rubber" />
                                <div className="pencil__body" />
                            </div>
                            <div className="pencil__line" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default About;
